<template>
  <div class="common account-info-page">
    <h2 class="page-title" v-if="authenticationMethod == 0">基础认证</h2>
    <h2 class="page-title" v-if="authenticationMethod == 1 && !highDisabled">
      高级认证
    </h2>
    <div class="page-content">
      <!-- 基础认证信息 -->
      <div class="form form-input">
        <div
          class="input-div"
          v-if="authenticationMethod == 0 || (status > 0 && highDisabled)"
        >
          <label for="name" class="form-label">姓名</label>
          <div class="input">
            <div class="input-content">
              <input
                type="text"
                placeholder="请输入您的姓名"
                form="baseInfo"
                v-model="baseInfo.name"
                v-checkParam="baseRules.name"
                :disabled="baseDisabled"
              />
            </div>
          </div>
        </div>
        <div
          class="input-div"
          v-if="authenticationMethod == 0 || (status > 0 && highDisabled)"
        >
          <label for="name" class="form-label">身份证</label>
          <div class="input">
            <div class="input-content">
              <input
                type="text"
                placeholder="请输入您的身份证"
                form="baseInfo"
                v-model="baseInfo.credentialsNum"
                v-checkParam="baseRules.credentialsNum"
                :disabled="baseDisabled"
              />
            </div>
          </div>
        </div>
        <div class="input-div" v-if="status > 0 && highDisabled">
          <label for="name" class="form-label">手机</label>
          <div class="input">
            <div class="input-content">
              <input type="text" v-model="mobile" disabled />
            </div>
          </div>
        </div>
        <div class="input-div" v-if="status > 0 && highDisabled">
          <label for="name" class="form-label">身份资料</label>
          <div class="input">
            <div class="input-content">
              <input
                type="text"
                v-if="status == 1"
                value="审核中，请等待"
                class="status-1"
                disabled
              />
              <input
                type="text"
                v-if="status == 3"
                value="审核未通过"
                class="status-3"
                disabled
              />
              <i v-if="status == 2" class="succ disabled"></i>
              <input
                type="text"
                v-if="status == 2"
                value="已通过"
                class="status-2"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 高级认证信息 -->
      <div
        class="high-upload"
        v-if="authenticationMethod == 1 && !highDisabled"
      >
        <van-uploader
          class="uploader uploader_01"
          :after-read="onRead"
          :max-size="5242800"
          @oversize="overSize"
          :disabled="highDisabled"
          form="highInfo"
          upload-icon=""
          :style="
            highInfo.idcardFrontimg
              ? `backgroundImage: url(${highInfo.idcardFrontimg})`
              : ''
          "
        >
        </van-uploader>
        <van-uploader
          class="uploader uploader_02"
          :after-read="onRead2"
          :max-size="5242800"
          @oversize="overSize"
          :disabled="highDisabled"
          form="highInfo"
          upload-icon=""
          :style="
            highInfo.idcardWrongimg
              ? `backgroundImage: url(${highInfo.idcardWrongimg})`
              : ''
          "
        >
        </van-uploader>
      </div>
      <!-- 审核中和审核成功显示身份证图片 -->
      <div
        class="show-upload"
        v-if="(status == 1 || status == 2) && imgList.length > 0"
      >
        <img
          class="uploader-img"
          v-for="(item, index) in imgList"
          :src="item.url"
          alt=""
          :key="index"
        />
      </div>
      <!-- 基础认证提示和按钮 -->
      <template v-if="authenticationMethod == 0 && !baseDisabled">
        <p class="tips base-tips">
          温馨提示：只要完成基础信息的填写，就可以进行入金。
        </p>
        <button
          class="button base-btn"
          v-checkSubmit="'baseInfo'"
          :submit="handleSubmitBase"
          :disabled="baseDisabled"
        >
          提交
        </button>
      </template>
      <!-- 高级认证提示和按钮 -->
      <template v-if="authenticationMethod == 1 && !highDisabled">
        <div class="high-tips tips">
          <h3>照片须知：</h3>
          <p>1、请确保上传照片的清晰程度足够高</p>
          <p>2、控制上传照片曝光度不要过高</p>
          <p>3、不能遮挡身份证信息</p>
          <p class="red high-p">
            为保证您的审核快速通过，请遵守上述规则，信息提交后，您可以在实名认证页面查看审核进度
          </p>
        </div>
        <div class="btn-div">
          <!--  -->
          <button
            class="button"
            v-checkSubmit="'highInfo'"
            :submit="handleSubmitHigh"
            :disabled="highDisabled"
          >
            提交
          </button>
        </div>
      </template>
      <!-- 审核失败原因 -->
      <template v-if="status == 3 && noCardReason && highDisabled">
        <div class="high-tips tips">
          <h3>失败原因：</h3>
          <p class="red">{{ noCardReason }}</p>
        </div>
        <div class="btn-div">
          <button class="button base-btn" @click="handleReSubmit">
            重新提交
          </button>
        </div>
      </template>
    </div>
    <Dialog
      :dialogObj="dialogObj"
      @confirm="handleDialogConfirm"
      @cancel="handleDialogCancel"
    />
  </div>
</template>

<script>
import account from "@/apis/account.js";
import Dialog from "@/components/dialog.vue";
import { oss } from "@/mixins/ossUpload.js";
import { serve } from "@/mixins/serve.js";
export default {
  name: "Account",
  components: {
    Dialog,
  },
  mixins: [oss, serve],
  data() {
    let idcardFrontimg = (value, callback) => {
      if (this.highInfo.idcardFrontimg === "") {
        callback("请上传身份证正面");
      }
    };
    let idcardWrongimg = (value, callback) => {
      if (this.highInfo.idcardWrongimg === "") {
        callback("请上传身份证反面");
      }
    };
    return {
      authenticationMethod: 0,
      baseInfo: {
        name: "",
        credentialsNum: "",
      },
      highInfo: {
        idcardFrontimg: "",
        idcardWrongimg: "",
        passPortImg: "",
      },
      mobile: "",
      status: 0,
      noCardReason: "",
      imgList: [], //身份证图片列表
      baseRules: {
        name: [{ rule: "required", msg: "姓名不能为空!" }],
        credentialsNum: [
          { rule: "required", msg: "证件号码不能为空!" },
          {
            rule: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            msg: "证件号码格式错误",
          },
        ],
      },
      highRules: {
        idcardFrontimg: [idcardFrontimg],
        idcardWrongimg: [idcardWrongimg],
      },
      baseDisabled: true,
      highDisabled: true,
      isShow: false,
      dialogObj: {
        isShow: false,
        title: "",
        content: "",
        confirmText: "",
        cancelText: "",
      },
    };
  },
  methods: {
    //设置图片  type=1  正面地址  type=2 反面地址
    setImgUrl(url, type) {
      if (type == 1) {
        this.highInfo.idcardFrontimg = url;
      } else {
        this.highInfo.idcardWrongimg = url;
      }
    },
    // 上传正面
    onRead(file) {
      this.doUpload(file.file, 1);
    },
    // 上传背面
    onRead2(file) {
      this.doUpload(file.file, 2);
    },
    handleSubmitBase() {
      if (this.authenticationMethod != 0 || this.baseDisabled) {
        return;
      }
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "提交中...",
      });
      this.baseDisabled = true;
      let params = {
        issueCountry: 1,
        credentialsType: 1,
        type: 1,
      };
      account
        .submitInfo(Object.assign({}, this.baseInfo, params))
        .then((res) => {
          this.$toast.clear();
          if (res.IsSuccess) {
            this.$toast.success("提交成功");
            //更新认证方式 显示第二步
            this.authenticationMethod = 1;
            this.highDisabled = false;

            //更新用户信息
            this.$store.dispatch("login/getUserInfo", {
              password: this.$store.state.login.userInfo.password,
            });
          } else {
            //this.$toast(res.Data);
            this.dialogObj = {
              isShow: true,
              title: "异常提示",
              content: res.Data,
              confirmText: "人工客服",
              cancelText: "确定",
            };
            this.baseDisabled = false;
          }
        });
    },
    handleSubmitHigh() {
      if (this.highDisabled) {
        return;
      }
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "提交中...",
      });
      if (this.highInfo.idcardFrontimg === "") {
        this.$toast.clear();
        this.$toast("请上传身份证人像面");
        return;
      }
      if (this.highInfo.idcardWrongimg === "") {
        this.$toast.clear();
        this.$toast("请上传身份证国徽面");
        return;
      }
      this.highDisabled = true;
      let params = {
        issueCountry: 1,
        credentialsType: 1,
        type: 2,
      };
      account
        .submitInfo(Object.assign({}, this.baseInfo, this.highInfo, params))
        .then((res) => {
          this.$toast.clear();
          if (res.IsSuccess) {
            //更新用户信息
            this.$store.dispatch("login/getUserInfo", {
              password: this.$store.state.login.userInfo.password,
            });
            this.dialogObj = {
              isShow: true,
              title: "",
              content: "您的信息已提交",
              showSuccImg: true,
              subContent: "我们会尽快完成审核",
              confirmText: "返回首页",
              cancelText: "返回账户信息",
            };
          } else {
            //this.$toast(res.data.Data);
            this.dialogObj = {
              isShow: true,
              title: "异常提示",
              content: res.Data,
              confirmText: "人工客服",
              cancelText: "确定",
            };
            this.highDisabled = false;
          }
        });
    },
    //获取账户资料基本信息
    getIdentityInfo() {
      account.getIdentityInfo().then((res) => {
        if (res.IsSuccess) {
          const data = res.Data;
          this.authenticationMethod = data.jfUser.authenticationMethod;
          this.baseInfo.name = data.jfUser.name;
          this.baseInfo.credentialsNum =
            data.jfUser.jfUserBindcard.credentialsNum;
          this.mobile = data.jfUser.mobile;
          this.status = data.jfUser.jfUserBindcard.credentialsStatus;
          this.noCardReason = data.jfUser.jfUserBindcard.noCardReason;
          //判断步骤
          //未通过基础认证
          if (data.jfUser.authenticationMethod == 0) {
            this.baseDisabled = false;
          }
          //已通过基础认证 未通过高级认证
          if (data.jfUser.authenticationMethod == 1) {
            this.highDisabled = false;
            if (parseInt(this.status) > 0) {
              //1待审核 2审核通过 3审核失败
              this.highDisabled = true;
            }
          }
          //已通过高级认证
          // if (data.jfUser.authenticationMethod == 2) {

          // }
          //身份证图片
          if (data.jfUser.jfUserBindcard.idcardFrontimg) {
            this.highInfo.idcardFrontimg =
              data.jfUser.jfUserBindcard.idcardFrontimg;
            this.imgList.push({ url: this.highInfo.idcardFrontimg });
          }
          if (data.jfUser.jfUserBindcard.idcardWrongimg) {
            this.highInfo.idcardWrongimg =
              data.jfUser.jfUserBindcard.idcardWrongimg;
            this.imgList.push({ url: this.highInfo.idcardWrongimg });
          }
        }
      });
    },
    handleReSubmit() {
      this.highDisabled = false;
      this.highInfo.idcardFrontimg = "";
      this.highInfo.idcardWrongimg = "";
    },
    handleDialogConfirm() {
      if (this.dialogObj.confirmText == "返回首页") {
        this.$router.push({
          name: "index",
        });
      } else {
        //客服
        this.doServe();
      }
    },
    handleDialogCancel() {
      if (this.dialogObj.cancelText == "返回账户信息") {
        this.imgList = [
          { url: this.highInfo.idcardFrontimg },
          { url: this.highInfo.idcardWrongimg },
        ];
        //更新状态为审核中
        this.status = 1;
        this.highDisabled = true;
      }
    },
  },
  mounted() {
    this.getIdentityInfo();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/account/account.scss";
</style>
